import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReactHtmlParser from 'react-html-parser';
import ScrollToBottom from 'react-scroll-to-bottom';

function Chat({ socket, firstname, botPersona }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  // Create an event handler so you can call the verification on button click event or form submit
  const sendMessage = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('sendMessage');

    if (currentMessage !== "") {
      const messageData = {
        author: firstname,
        currentMessage: { role: "user", content: currentMessage },
        time: new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      }
      setMessageList((list) => [...list, messageData]);
      
      messageData.reCaptchaToken = token;
      messageData.botPersona = botPersona;
      messageData.messageHistory = messageList.map(m => m.currentMessage);
      await socket.emit("send_message", messageData);

      setCurrentMessage("");
    }
  }, [executeRecaptcha, firstname, botPersona, messageList, currentMessage, socket]);

  useEffect(() => {
    socket.on("receive_message", (messageData) => {
      setMessageList((list) => [...list, messageData]);
    });
  }, [socket]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <p>WiseBot - {botPersona}</p>
      </div>
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {messageList.map((messageData) => {
            return (
              <div
                className="message"
                id={firstname === messageData.author ? "you" : "other"}
              >
                <div>
                  <div className="message-content">
                    <p>{ReactHtmlParser(messageData.currentMessage.content)}</p>
                  </div>
                  <div className="message-meta">
                    <p id="time">{messageData.time}</p>
                    <p id="author">{messageData.author}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollToBottom>
      </div>
      <div className="chat-footer">
        <input
          type="text"
          value={currentMessage}
          placeholder="Voice type / dictate a message"
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />
        <button onClick={sendMessage}>&#9658;</button>
      </div>
    </div>
  );
}

export default Chat;
