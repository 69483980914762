module.exports = {
    // COMMON
    RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY ?? "6LcCYhYmAAAAAPhb3zDuE8ay05fogYHOGfVF2SZz",
    RESERVED_FIRSTNAME: process.env.RESERVED_FIRSTNAME ?? "gpt-4o",

    // DEV
    // SOCKET_IO_URL: "http://localhost:3001",

    // PROD
    SOCKET_IO_URL: "https://wisebotapi.loftysolns.com",
}
