import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import io from 'socket.io-client';
import './App.css';
import Chat from './Chat';
import config from './config';

let socket = io.connect(config.SOCKET_IO_URL);

function App() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [firstname, setFirstname] = useState(Cookies.get('firstname'));
  const [botPersona, setBotPersona] = useState("");
  const [showChat, setShowChat] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const joinChat = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('joinChat');

    if (firstname !== "" && firstname !== config.RESERVED_FIRSTNAME &&
        botPersona !== "") {
      const messageData = {
        reCaptchaToken: token,
        author: firstname,
        botPersona: botPersona,
        time: new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
      };

      socket.emit("set_botPersona", messageData);
      setShowChat(true);
    }
  }, [executeRecaptcha, firstname, botPersona]);

  return (
    <div className="App">
      {!showChat ? (
        <div className="joinChatContainer">
          <img src="wisebot.svg" alt="WiseBot"></img>
          <h3>WiseBot</h3>
          <input
            type="text"
            placeholder="Enter your firstname"
            value={ firstname }
            onChange={(event) => {
              Cookies.set('firstname', event.target.value, { expires: 400 });
              setFirstname(event.target.value);
            }}
          />

          <select
            onmousedown="this.value='';"  
            onChange={(event) => {
              setBotPersona(event.target.value);
            }}>
              <option value="">Select bot persona</option>
              <option value="Expert (Short Answers)">Expert (Short Answers)</option>
              <option value="Expert (Long Answers)">Expert (Long Answers)</option>
              <option value="Friendly / Creative">Friendly / Creative</option>
              <option value="Chef">Chef</option>
              <option value="Nutri Guide">Nutri Guide</option>
              <option value="Lexicologist (Dict., Thes.)">Lexicologist (Dict., Thes.)</option>
              <option value="Proofreader (Spell., Gram.)">Proofreader (Spell., Gram.)</option>
              <option value="Translator (Any to English)">Translator (Any to English)</option>
              <option value="Summarizer">Summarizer</option>
              <option value="Prose Writer">Prose Writer</option>
              <option value="Poet">Poet</option>
              <option value="Song Writer">Song Writer</option>
              <option value="Sarcastic">Sarcastic</option>
              <option value="_">_</option>
          </select>
          <button onClick={joinChat}>Join Chat</button>
        </div>
      ) : (
        <Chat socket={socket} firstname={firstname} botPersona={botPersona} />
      )}
      <div class="notice">
        <b>Privacy & Terms of Use</b> <br />
        No data collection; <a href="https://openai.com/policies" target="_blank" rel="noreferrer">OpenAI</a> may retain info. Avoid sharing sensitive/personal details. Content for informational use only. Users assume all risks; no liability for losses/damages.
      </div>
    </div>
  );
}

export default App;
